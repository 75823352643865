/* eslint-disable react/jsx-no-bind */
import { useState, useEffect } from 'react';
import { ListGroup, Offcanvas } from 'react-bootstrap';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import CRUDExpense from './CRUDExpense';
import Expense from './Expense';
import Tags from './Tags';
import { formatPrice, unpaidDaysSinceInvoice } from './utils';

ChartJS.register(ArcElement, Tooltip, Legend);

/* eslint-disable react/prop-types */
function Expenses({ expenses }) {
  const [showExpense, setShowExpense] = useState(false);
  const [expenseIndex, setExpenseIndex] = useState(-1);
  const [tagFilter, setTagFilter] = useState([]);
  const [filteredExpenses, setFilteredExpenses] = useState([...expenses]);

  useEffect(() => {
    setFilteredExpenses(expenses.filter((expense) => {
      if (!tagFilter.length) return true;

      return expense.data().tags.some((tag) => tagFilter.includes(tag));
    }));
  }, [expenses, tagFilter]);

  function updateTagFilters(tag, removeTagFromFilters) {
    if (removeTagFromFilters) {
      const index = tagFilter.indexOf(tag);
      const newTagFilter = [...tagFilter];
      newTagFilter.splice(index, 1);
      setTagFilter(newTagFilter);
    } else {
      setTagFilter([...tagFilter, tag]);
    }
  }

  function handleClose() {
    setShowExpense(false);
  }
  function onExpenseClicked(index) {
    setExpenseIndex(index);
    setShowExpense(true);
  }
  function toggleShowExpenses() {
    setShowExpense(!showExpense);
  }
  let total = 0;
  let pending = 0;
  let hasOpen = false;
  let paidCount = 0;

  const tags = expenses
    .flatMap((expense) => expense.data().tags)
    .map((tag) => tag.trim())
    .filter((tag, index, self) => self.indexOf(tag) === index);

  const expensesDom = filteredExpenses.map((expense, idx) => {
    const { price, paidDate, invoiceDate } = expense.data();

    if (paidDate) {
      total += price;

      paidCount += 1;
    } else {
      pending += price;

      if (unpaidDaysSinceInvoice(new Date(invoiceDate)) >= 0) {
        paidCount += 1;
      } else {
        hasOpen = true;
      }
    }

    return (
      <div key={expense.id}>
        <Expense
          expense={expense.data()}
          index={idx}
          firstNotOpen={hasOpen && paidCount === 1}
          clickHandler={onExpenseClicked}
        />
      </div>
    );
  });
  if (!expenses) return 'loading';
  return (
    <>
      <div className="m-3 text-center">
        Insgesamt ausgegeben:
        {' '}
        {formatPrice([total])}
        <p className="text-secondary" style={{ fontSize: '0.9em' }}>
          Verplant:
          {' '}
          {formatPrice([pending + total])}
        </p>
      </div>
      <div style={{ maxWidth: '300px', margin: '0 auto' }}>
        <Pie
          className="mb-4"
          data={{
            labels: ['Bereits ausgegeben', 'Budget verbleibend', 'Offene Rechnungen'],
            datasets: [{
              data: [total, 150000 - total, pending],
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(123, 123, 122, 0.2)',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(123, 123, 122, 1)',
              ],
              borderWidth: 1,
            },
            ],
          }}
        />
      </div>
      <div className="m-3">
        <Tags tags={tags} activeTags={tagFilter} onClickHandler={updateTagFilters} />
        {/* <Tags tags={['ohne tag']} activeTags={} onClickHandler={updateTagFilters} /> */}
      </div>
      <div className="m-3">
        <ListGroup numbered>
          { expensesDom }
        </ListGroup>
      </div>
      {expenseIndex >= 0
      && (
      <Offcanvas show={showExpense} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Ausgabe</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CRUDExpense
            expenseRef={filteredExpenses[expenseIndex]}
            onSubmitHandler={() => toggleShowExpenses()}
          />
        </Offcanvas.Body>
      </Offcanvas>
      )}
    </>
  );
}

export default Expenses;
