const formatPrice = (price) => Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
}).format(price);

const unpaidDaysSinceInvoice = (invoiceDate) => {
  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;
  const today = new Date();

  // Calculating the time difference between two dates
  const diffInTime = today.getTime() - invoiceDate.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.ceil(diffInTime / oneDay);

  return diffInDays - 1;
};
export { formatPrice, unpaidDaysSinceInvoice };
