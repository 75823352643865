/* eslint-disable react/prop-types */

import './Tags.css';

function Tags({ tags, activeTags, onClickHandler }) {
  return (
    <div>
      {tags.map((tag) => {
        const isActive = activeTags.includes(tag);
        const classes = `tag${isActive ? ' active' : ''}`;
        return (
          <button
            className={classes}
            type="button"
            key={tag}
            onClick={() => onClickHandler(tag, isActive)}
          >
            {tag}
            {isActive && <i className="bi bi-x" />}
          </button>
        );
      })}
    </div>
  );
}

export default Tags;
