/* eslint-disable consistent-return */
import './App.css';
import { useState, useEffect } from 'react';
import {
  collection, query, orderBy, onSnapshot,
} from 'firebase/firestore';
import Button from 'react-bootstrap/Button';
import { Offcanvas } from 'react-bootstrap';
import { useAuthState } from 'react-firebase-hooks/auth';
import Container from './Container';
import Expenses from './Expenses';
import { db, auth } from './firebase';
import CRUDExpense from './CRUDExpense';
import Login from './Login';

const buttonStyles = {
  position: 'fixed',
  bottom: '20px',
  right: '20px',
};

function App() {
  const [expenses, setExpenses] = useState([]);
  const [showAddExpanses, setShowAddExpanses] = useState(false);
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    const q = query(collection(db, 'expenses'), orderBy('invoiceDate', 'desc'));
    onSnapshot(q, (querySnapshot) => {
      setExpenses(querySnapshot.docs);
    });
  }, []);

  function toggleAddExpense() {
    setShowAddExpanses(!showAddExpanses);
  }

  const handleClose = () => setShowAddExpanses(false);
  if (loading) return 'loading';
  if (!user) return <Login />;

  return (
    <Container>
      <h1 style={{ textAlign: 'center' }}>Ausgaben</h1>
      <Expenses expenses={expenses} />
      <div style={buttonStyles}>
        <Button onClick={() => toggleAddExpense()} variant="primary">
          <i className="bi bi-plus-lg" />
        </Button>
      </div>
      <Offcanvas show={showAddExpanses} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Ausgabe hinzufügen</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CRUDExpense onSubmitHandler={() => toggleAddExpense()} />
        </Offcanvas.Body>
      </Offcanvas>
    </Container>
  );
}

export default App;
