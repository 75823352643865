/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  collection, addDoc, Timestamp, updateDoc, doc, deleteDoc,
} from 'firebase/firestore';
import { Button, Form } from 'react-bootstrap';
import {
  ref, uploadBytes, getDownloadURL, deleteObject,
} from 'firebase/storage';
import { useState } from 'react';
import { auth, db, storage } from './firebase';
import useForm from './useForm';
import './crudExpense.css';

function CRUDExpense({ onSubmitHandler, expenseRef }) {
  const expense = expenseRef?.data();
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [tags, setTags] = useState(expense?.tags || []);
  const [tagInput, setTagInput] = useState('');
  const { inputs, handleChange, resetForm } = useForm({
    title: expense?.title || '',
    date: expense?.invoiceDate || '',
    paidDate: expense?.paidDate || '',
    tax: expense?.tax || false,
    price: expense?.price || '',
    description: expense?.description || '',
    img: {},
  });

  const onChange = (e) => {
    const { value } = e.target;
    setTagInput(value);
  };

  const onKeyDown = (e) => {
    const { which } = e;
    const trimmedInput = tagInput.trim();

    if (which === 190 && trimmedInput.length && !tags.includes(trimmedInput)) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setTagInput('');
    }

    if (which === 8 && !tagInput.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setTagInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((_, i) => i !== index));
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const deleteImgFromStorage = async () => {
    await deleteObject(ref(storage, expense.imgName));
    expense.img = null;
    expense.imgName = null;
  };

  const deleteExpense = async () => {
    setLoading(true);

    try {
      if (expense.imgName) {
        deleteImgFromStorage();
      }
      await deleteDoc(doc(db, 'expenses', expenseRef.id));
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
    onSubmitHandler();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let imgURL;
    let imgName;
    try {
      if (inputs.img.name) {
        const prefix = Math.random() * 10000;
        imgName = `${prefix}_${inputs.img.name}`;
        const storageRef = ref(storage, imgName);
        await uploadBytes(storageRef, inputs.img);
        imgURL = await getDownloadURL(storageRef);
      }
      if (!editMode) {
        await addDoc(collection(db, 'expenses'), {
          title: inputs.title,
          description: inputs.description,
          invoiceDate: inputs.date,
          price: inputs.price,
          tax: inputs.tax,
          img: imgURL || null,
          imgName: imgName || null,
          createdBy: auth.currentUser.uid,
          created: Timestamp.now(),
          tags,
          paidDate: inputs.paidDate,
        });
      } else {
        const newData = {
          title: inputs.title,
          description: inputs.description,
          invoiceDate: inputs.date,
          price: inputs.price,
          tax: inputs.tax,
          modifiedBy: auth.currentUser.uid,
          modified: Timestamp.now(),
          paidDate: inputs.paidDate,
          tags,
        };
        if (imgURL) {
          deleteImgFromStorage();
          newData.img = imgURL;
          newData.imgName = imgName;
        }
        await updateDoc(doc(db, 'expenses', expenseRef.id), newData);
      }

      onSubmitHandler();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    setLoading(false);
  };
  const deleteButton = (
    <Button variant="primary" type="submit">
      löschen
    </Button>
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Titel</Form.Label>
        <Form.Control disabled={expense && !editMode} required type="text" name="title" id="title" value={inputs.title} onChange={handleChange} />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Beschreibung</Form.Label>
        <Form.Control disabled={expense && !editMode} as="textarea" rows={3} name="description" id="description" value={inputs.description} onChange={handleChange} />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Betrag</Form.Label>
        <Form.Control required disabled={expense && !editMode} type="number" min="0.1" step="0.01" name="price" id="price" value={inputs.price} onChange={handleChange} />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Rechnungsdatum</Form.Label>
        <Form.Control required disabled={expense && !editMode} type="date" value={inputs.date} id="date" onChange={handleChange} name="date" />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Bezahlt am</Form.Label>
        <Form.Control disabled={expense && !editMode} type="date" value={inputs.paidDate} id="date" onChange={handleChange} name="paidDate" />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Tags</Form.Label>
        <div className="container" disabled={expense && !editMode}>
          {tags.map((tag, index) => (
            <div className="tagContainer" key={tag}>
              {tag}
              {!expense || editMode ? <button onClick={() => deleteTag(index)}><i className="bi bi-trash3" /></button> : ''}

            </div>
          ))}
          <Form.Control
            placeholder="Enter a tag"
            name="tags"
            type="text"
            disabled={expense && !editMode}
            value={tagInput}
            onKeyDown={onKeyDown}
            onChange={onChange}
            onKeyUp={onKeyUp}
          />
        </div>
      </Form.Group>

      {expense && !editMode
        ? (
          expense.img
            ? <a href={expense.img} target="_blank" className="mb-3 d-inline-block" download rel="noreferrer"><img style={{ width: '100%' }} src={expense.img} alt="rechnung" /></a>
            : ''

        )
        : (
          <Form.Group className="mb-3">
            <Form.Label>Bild</Form.Label>
            <Form.Control type="file" name="img" id="img" onChange={handleChange} />
          </Form.Group>
        )}

      <Form.Check
        type="switch"
        label="Für die Steuer relevant?"
        name="tax"
        id="tax"
        value={inputs.tax}
        onChange={handleChange}
        className="mb-3"
        disabled={expense && !editMode}
      />

      {!expense || editMode
        ? loading
          ? (
            <Button variant="primary" disabled type="submit">
              <span className="spinner-border" role="status" />
              Speichere...
            </Button>
          )
          : (
            editMode ? (
              <div className="d-flex justify-content-between">
                <Button variant="primary" type="submit">
                  Änderungen speichern
                </Button>
                <Button variant="danger" onClick={(e) => { e.preventDefault(); deleteExpense(); }}>
                  löschen
                </Button>
              </div>
            ) : (
              <Button variant="primary" type="submit">
                Ausgabe hinzufügen
              </Button>
            )
          )
        : (
          <Button variant="primary" type="button" onClick={(e) => { e.preventDefault(); setEditMode(true); }}>
            Bearbeiten
          </Button>
        )}
    </Form>
  );
}

export default CRUDExpense;
