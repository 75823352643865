/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */

import { ListGroup } from 'react-bootstrap';
import { formatPrice, unpaidDaysSinceInvoice } from './utils';

/* eslint-disable react/prop-types */
function Expense({
  expense, clickHandler, index, firstNotOpen,
}) {
  const additionalClass = firstNotOpen ? ' mt-4' : '';
  function clicked() {
    clickHandler(index);
  }

  function getPaymentStatus() {
    const result = {
      alreadyPaid: !!expense.paidDate,
    };

    if (expense.paidDate) return result;

    const invoiceDate = new Date(expense.invoiceDate);
    const diff = unpaidDaysSinceInvoice(invoiceDate);

    result.daysSinceInvoice = diff;

    if (diff >= 0 && diff < 7) {
      result.variant = 'warning';
    } else {
      result.variant = 'danger';
    }

    if (diff < 0) {
      result.variant = 'secondary';
    }

    return result;
  }

  const status = getPaymentStatus();

  function getDayHint() {
    if (status.daysSinceInvoice > 1) {
      return `${status.daysSinceInvoice} Tage unbezahlt`;
    }

    if (status.daysSinceInvoice === 1) {
      return '1 Tag unbezahlt';
    }

    if (status.daysSinceInvoice === 0) {
      return 'Neu';
    }

    if (status.daysSinceInvoice < 0) {
      return 'vorgemerkt';
    }

    return '';
  }
  return (
    <ListGroup.Item
      onClick={clicked}
      action
      className={`d-flex justify-content-between align-items-center${additionalClass}`}
      variant={status.variant}
    >
      <div className="ms-2 me-auto">
        <div className="fw-bold">{expense.title}</div>
        {expense.description}
      </div>
      <div className="d-flex flex-column align-items-end">
        <div>
          {formatPrice(expense.price)}
        </div>
        <div>
          {getDayHint()}
        </div>
      </div>
    </ListGroup.Item>

  );
}

export default Expense;
