import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyDJqhEzp0eO9GZ-6aN99CnKk0VK9ZHlpl8',
  authDomain: 'costmanager-69c9f.firebaseapp.com',
  projectId: 'costmanager-69c9f',
  storageBucket: 'costmanager-69c9f.appspot.com',
  messagingSenderId: '679448688282',
  appId: '1:679448688282:web:fb9349e1a4fabe2da39123',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
  }
};

export {
  db, storage, auth, logInWithEmailAndPassword,
};
