const styles = {
  maxWidth: '800px',
  margin: '40px auto 0',
  padding: '0 10px',
};

// eslint-disable-next-line react/prop-types
function Container({ children }) {
  return (
    <div style={styles}>
      {children}
    </div>
  );
}

export default Container;
