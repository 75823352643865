/* eslint-disable react/jsx-no-bind */
import { Button, Form } from 'react-bootstrap';
import Container from './Container';
import { logInWithEmailAndPassword } from './firebase';
import useForm from './useForm';

function Login() {
  const { inputs, handleChange, resetForm } = useForm({
    email: '',
    password: '',
  });

  function handleSubmit(e) {
    e.preventDefault();
    logInWithEmailAndPassword(inputs.email, inputs.password);
    resetForm();
  }
  return (
    <Container>
      <h1 style={{ textAlign: 'center' }}>Login</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>E-Mail</Form.Label>
          <Form.Control required type="email" name="email" id="email" value={inputs.email} onChange={handleChange} />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control required type="password" name="password" id="password" value={inputs.password} onChange={handleChange} />
        </Form.Group>

        <Button variant="primary" type="submit">
          Einloggen
        </Button>
      </Form>
    </Container>

  );
}

export default Login;
